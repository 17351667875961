import "./App.css";
import React, { useEffect, useState } from "react";
import Router from "./routes";
import axios from "axios";
import { backendServerBaseURL } from "./utils/backendServerBaseURL";

const LightTheme = React.lazy(() => import("./theme/LightTheme"));
const DarkTheme = React.lazy(() => import("./theme/DarkTheme"));
export const ThemeContext = React.createContext();

function App() {
  const defaultTheme = "light";
  const storedTheme = localStorage.getItem("theme", defaultTheme);

  const [selectedTheme, setselectedTheme] = useState(defaultTheme);

  useEffect(async () => {
    if (storedTheme === null || storedTheme === undefined || storedTheme === "") {
      await localStorage.setItem("theme", defaultTheme);
      setselectedTheme(storedTheme);
    }

    if (storedTheme) {
      setselectedTheme(storedTheme);
    }
  }, [selectedTheme]);

  const ThemeSelector = ({ selectedThemeStr, children }) => {
    console.log(selectedThemeStr);
    // const CHOSEN_THEME = localStorage.getItem("TYPE_OF_THEME") || TYPE_OF_THEME.DEFAULT;
    return (
      <>
        <React.Suspense fallback={<></>}>
          {selectedThemeStr === "light" && <LightTheme />}
          {selectedThemeStr === "dark" && <DarkTheme />}
        </React.Suspense>
        {children}
      </>
    );
  };

  return (
    <>
      <ThemeContext.Provider value={{ selectedTheme, setselectedTheme }}>
        <ThemeSelector selectedThemeStr={selectedTheme}>
          <Router />
        </ThemeSelector>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
