import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableCustomer, resolveFeedbacks, selectselectedFeedback } from "../redux/slices/adminSlice";

function ViewFeedbackDialog() {
  const selectedFeedback = useSelector(selectselectedFeedback);
  const dispatch = useDispatch();
  const [error, seterror] = useState(null);
  const closeDialogRef = useRef();

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="viewFeedbackDialog" tabIndex={-1} aria-labelledby="disableCustomerDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Feedback</h2>

              <p className="mb-0">Category</p>
              <p className="mb-3 body2">{selectedFeedback?.category}</p>

              <p className="mb-0">Description</p>
              <p className="mb-0 body1 mt-2">{selectedFeedback?.title}</p>
              <p className="mb-3">{selectedFeedback?.description}</p>

              <div className="hstack gap-2 d-flex justify-content-end">
                <button
                  type="submit"
                  class="btn btn-lg btn-primary"
                  onClick={() => {
                    dispatch(resolveFeedbacks({ feedbackId: selectedFeedback?._id, closeDialog }));
                  }}
                >
                  Resolve
                </button>
                <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewFeedbackDialog;
