import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/common/Alert";
import { disableCustomer, selectselectedCustomer } from "../redux/slices/adminSlice";

function DisableCustomerDialog() {
  const selectedCustomer = useSelector(selectselectedCustomer);
  const dispatch = useDispatch();
  const [error, seterror] = useState(null);
  const closeDialogRef = useRef();

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="disableCustomerDialog" tabIndex={-1} aria-labelledby="disableCustomerDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Disable Account</h2>

              <div className="form-field">
                Are you sure ? All the users invited to through this email will not be able to access the dashboard till you enable it back
              </div>

              {error && <Alert value={error} variant={"danger"} />}

              <div className="hstack gap-2 d-flex justify-content-end">
                <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}>
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  onClick={() => {
                    dispatch(disableCustomer({ userId: selectedCustomer?.id, seterror, closeDialog }));
                  }}
                >
                  Disable Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DisableCustomerDialog;
