import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, readAllFeedbacks, selectallFeedbacks, selectUsers, updateselectedFeedback } from "../redux/slices/adminSlice";
import { formatTime } from "../utils/formatTime";
import DisableCustomerDialog from "./DisableCustomerDialog";
import EnableCustomerDialog from "./EnableCustomerDialog";
import NewCustomerDialog from "./NewCustomerDialog";
import ViewFeedbackDialog from "./ViewFeedbackDialog";

function Feedback() {
  const [selectedMenu, setselectedMenu] = useState("Active");
  const users = useSelector(selectUsers);
  const allFeedbacks = useSelector(selectallFeedbacks);
  const dispatch = useDispatch();

  const ProjectsMenus = [
    {
      name: "Active",
    },
    {
      name: "Resolved",
    },
  ];

  const RightProjectsMenus = [];

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(readAllFeedbacks());
  }, [selectedMenu]);

  return (
    <div className="page-body">
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Feedback</h1>
          <p className="text-secondary">Feedback submitted by the user</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}></div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {allFeedbacks.length === 0 && (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <div className="vstack gap-3 text-center">
            <img src="/static/illustrations/no-projects-found.svg" alt="" height="200px" />
            <h2 className="body1 regular-weight">No Feedback Found</h2>
          </div>
        </div>
      )}

      {allFeedbacks.length !== 0 && (
        <table className="table table-borderless mt-2 custom-table-sm">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Title
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Category
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Company
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                User
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                E-mail
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Date
              </td>
              <td scope="col" className="text-secondary body3 regular-weight"></td>
            </tr>
          </thead>
          <tbody>
            {allFeedbacks
              .filter((u) => {
                if (selectedMenu === "Active") return u.status === "active";
                if (selectedMenu === "Resolved") return u.status === "resolved";
                return true;
              })
              .map((user) => {
                return (
                  <tr className="border bg-white">
                    <td className="body3 regular-weight align-middle">{user?.title}</td>
                    <td className="body3 regular-weight align-middle">{user?.category}</td>
                    <td className="body3 regular-weight align-middle">{user?.organization}</td>
                    <td className="body3 regular-weight align-middle">{`${user?.user?.firstName} ${user?.user?.lastName}`}</td>
                    <td className="body3 regular-weight align-middle">{user?.user?.email}</td>
                    <td className="body3 regular-weight align-middle">{formatTime(user?.createdAt)}</td>
                    <td className="body3 regular-weight align-middle">
                      <div class="dropdown">
                        <i class="bi bi-three-dots-vertical cp" data-bs-toggle="dropdown"></i>

                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item body3 regular-weight"
                              data-bs-toggle="modal"
                              data-bs-target="#viewFeedbackDialog"
                              onClick={() => {
                                dispatch(updateselectedFeedback(user));
                              }}
                            >
                              View Feedback
                            </a>
                          </li>

                          <li>
                            <a
                              className="dropdown-item body3 regular-weight"
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#viewFeedbackDialog"
                              onClick={() => {
                                dispatch(updateselectedFeedback(user));
                              }}
                            >
                              Resolved
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      <NewCustomerDialog />
      <DisableCustomerDialog />
      <EnableCustomerDialog />
      <ViewFeedbackDialog />
    </div>
  );
}

export default Feedback;
