export default function Page404() {
  return (
    <>
      <main id="content" role="main" className="main">
        {/* Content */}
        <div className="container">
          <div className="footer-height-offset d-flex justify-content-center align-items-center flex-column">
            <div className="row justify-content-center align-items-sm-center w-100">
              <div className="col-9 col-sm-6 col-lg-4">
                <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
                  <img
                    className="img-fluid"
                    src="/static/svg/illustrations/oc-thinking.svg"
                    alt="Image Description"
                    data-hs-theme-appearance="default"
                  />
                </div>
              </div>
              {/* End Col */}
              <div className="col-sm-6 col-lg-4 text-center text-sm-start">
                <h1 className="display-1 mb-0">404</h1>
                <p className="lead">Sorry, the page you're looking for cannot be found.</p>
                <a className="btn btn-primary" href="/">
                  Go back to the App
                </a>
              </div>
              {/* End Col */}
            </div>
            {/* End Row */}
          </div>
        </div>
        {/* End Content */}
        {/* Footer */}
        <div className="footer text-center">
          <ul className="list-inline list-separator">
            <li className="list-inline-item">
              <a className="list-separator-link" href="#">
                Front Support
              </a>
            </li>
            <li className="list-inline-item">
              <a className="list-separator-link" href="#">
                Front Status
              </a>
            </li>
            <li className="list-inline-item">
              <a className="list-separator-link" href="#">
                Get Help
              </a>
            </li>
          </ul>
        </div>
        {/* End Footer */}
      </main>
    </>
  );
}
