import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/common/Alert";
import { disableCustomer, enableCustomer, selectselectedCustomer } from "../redux/slices/adminSlice";

function EnableCustomerDialog() {
  const selectedCustomer = useSelector(selectselectedCustomer);
  const dispatch = useDispatch();
  const [error, seterror] = useState(null);
  const closeDialogRef = useRef();

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="EnableCustomerDialog" tabIndex={-1} aria-labelledby="EnableCustomerDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Enable Account</h2>

              <div className="form-field">Everyone added via this email will have access to the dashbord</div>

              {error && <Alert value={error} variant={"danger"} />}

              <div className="hstack gap-2 d-flex justify-content-end">
                <button type="button" class="btn btn-lg btn-outline-danger" data-bs-dismiss="modal" ref={closeDialogRef}>
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-primary"
                  onClick={() => {
                    dispatch(enableCustomer({ userId: selectedCustomer?.id, seterror, closeDialog }));
                  }}
                >
                  Enable Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnableCustomerDialog;
