import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { deleteActionPlan } from "../../redux/slices/adminSlice";

function DeleteActionPlanDialog() {
  const dispatch = useDispatch();
  const closeModalRef = useRef();

  const closeModal = () => {
    document.elementFromPoint(100, 100).click();
    closeModalRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="DeleteActionPlanDialog" tabIndex={-1} aria-labelledby="deleteProjectDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Delete Action Plan</h2>

              <div className="form-field">
                <span>
                  Are you sure you want to delete this action plan? <span className="bold-weight">All the data will be erased.</span>
                </span>
              </div>

              <div className="hstack gap-2 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-lg btn-outline-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteProjectDialog"
                  onClick={() => {
                    dispatch(deleteActionPlan({ closeModal }));
                  }}
                >
                  Delete Action Plan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteActionPlanDialog;
