import React from "react";
import { Outlet } from "react-router-dom";
import Toolbar from "./Toolbar";

function MainLayout() {
  return (
    <div>
      <Toolbar />
      <Outlet />
    </div>
  );
}

export default MainLayout;
