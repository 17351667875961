import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Toolbar() {
  const [selectedMenu, setselectedMenu] = useState("User Management");
  const navigate = useNavigate();
  const menus = [
    {
      name: "User Management",
      link: "/user-management",
    },
    {
      name: "Action Plans",
      link: "/action-plans",
    },
    {
      name: "Analytics",
      link: "/analytics",
    },
    {
      name: "Feedback",
      link: "/feedback",
    },
  ];

  return (
    <div className="toolbar border-bottom d-flex align-items-center">
      <img src="/static/icons/logo.svg" alt="" />

      <div className="flex-fill d-flex justify-content-center align-items-center">
        {menus.map((menu) => {
          return (
            <Link
              to={menu.link}
              style={{ textDecoration: "none" }}
              className="text-dark body2"
              onClick={() => {
                setselectedMenu(menu.name);
              }}
            >
              <div
                className={
                  selectedMenu === menu.name
                    ? "text-center border-bottom border-primary border-3"
                    : "text-center pb-1"
                }
                style={{ minWidth: "7rem" }}
              >
                <p className="mb-1">{menu.name}</p>
              </div>
            </Link>
          );
        })}
      </div>

      <div class="dropdown">
        <img
          src="/static/icons/Atom=avatar, Name=aryan, Size=medium.svg"
          alt=""
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        {/* TODO: we can add name of superowner here */}
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <a
              class="dropdown-item"
              onClick={() => {
                localStorage.clear();
                navigate("/");
              }}
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Toolbar;
