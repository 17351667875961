import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/backendServerBaseURL";

const initialState = {
  users: [],
  selectedCustomer: null,

  actionPlans: [],
  selectedActionPlan: null,
  selectedCategory: null,
  selectedPointer: null,
  singlePointerInfo: null,

  // Feedbacks
  allFeedbacks: [],
  selectedFeedback: null,

  // Analytics
  anayticsData: [],
  span: "2weeks",
};

export const loginsuperOwner = createAsyncThunk(
  "admin/loginsuperOwner",
  async (payload, thunkAPI) => {
    const data = {
      email: payload.email,
      password: payload.password,
    };

    try {
      let response = await axios.post(
        `${backendServerBaseURL}/api/v1/admin/login`,
        data
      );

      if (
        response.status === 200 &&
        response.data.message === "Logged in successfully"
      ) {
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.superOwner));
        window.open("/user-management", "_self");
      }
    } catch (err) {
      payload.setErrors({ afterSubmit: err.response.data.message });
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "admin/getAllUsers",
  async (_, thunkAPI) => {
    let response = await axios.get(
      `${backendServerBaseURL}/api/v1/admin/readCustomers`
    );

    if (
      response.status === 200 &&
      response.data.message === "Customers retrieved successfully"
    ) {
      thunkAPI.dispatch(updateUsers(response.data.users));
    }
  }
);

export const createUser = createAsyncThunk(
  "admin/createUser",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.post(
        `${backendServerBaseURL}/api/v1/admin/invite`,
        {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          organization: payload.organization,
          limit: payload.noOfUserAccess,
        }
      );

      console.log(response);
      if (
        response.status === 201 &&
        response.data.message === "User invited successfully"
      ) {
        payload.closeDialog();
        payload.resetForm();
        thunkAPI.dispatch(getAllUsers());
      }
    } catch (err) {
      payload.setErrors({ afterSubmit: err.response.data.message });
    }
  }
);

export const updateUser = createAsyncThunk(
  "admin/updateUser",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.put(
        `${backendServerBaseURL}/api/v1/admin/editCustomer/${payload.userId}`,
        {
          firstName: payload.firstName,
          lastName: payload.lastName,
          email: payload.email,
          organization: payload.organization,
          limit: payload.noOfUserAccess,
        }
      );

      console.log(response);
      if (
        response.status === 200 &&
        response.data.message === "User updated successfully"
      ) {
        payload.closeDialog();
        payload.resetForm();
        thunkAPI.dispatch(getAllUsers());
      }
    } catch (err) {
      payload.setErrors({ afterSubmit: err.response.data.message });
    }
  }
);

export const disableCustomer = createAsyncThunk(
  "admin/disableCustomer",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.put(
        `${backendServerBaseURL}/api/v1/admin/disableCustomer/${payload.userId}`
      );

      if (
        response.status === 200 &&
        response.data.message === "User disabled successfully"
      ) {
        payload.closeDialog();
        thunkAPI.dispatch(getAllUsers());
      }
    } catch (err) {
      payload.seterror(err.response.data.message);
    }
  }
);

export const enableCustomer = createAsyncThunk(
  "admin/enableCustomer",
  async (payload, thunkAPI) => {
    try {
      let response = await axios.put(
        `${backendServerBaseURL}/api/v1/admin/enableCustomer/${payload.userId}`
      );

      if (
        response.status === 200 &&
        response.data.message === "User enabled successfully"
      ) {
        payload.closeDialog();
        thunkAPI.dispatch(getAllUsers());
      }
    } catch (err) {
      payload.seterror(err.response.data.message);
    }
  }
);

// Action Plans
export const getAllActionPlans = createAsyncThunk(
  "admin/getAllActionPlans",
  async (_, thunkAPI) => {
    let config = {
      params: {},
    };

    let response = await axios.get(
      `${backendServerBaseURL}/api/v1/plan/readExternal`,
      config
    );

    if (
      response.status === 200 &&
      response.data.message === "Plans fetched successfully"
    ) {
      thunkAPI.dispatch(updateActionPlans(response.data.plans));
    }
  }
);

export const createActionPlan = createAsyncThunk(
  "admin/createActionPlan",
  async (payload, thunkAPI) => {
    let response = await axios.post(
      `${backendServerBaseURL}/api/v1/plan/createExternal`,
      {
        name: payload.name,
      }
    );

    if (
      response.status === 200 &&
      response.data.message === "Plan created successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      payload.closeModal();
    }
  }
);

export const editActionPlan = createAsyncThunk(
  "admin/editActionPlan",
  async (payload, thunkAPI) => {
    let response = await axios.put(
      `${backendServerBaseURL}/api/v1/plan/update/${
        thunkAPI.getState().admin.selectedActionPlan._id
      }`,
      {
        name: payload.name,
      }
    );

    console.log(response.data);
    console.log(payload);
    if (
      response.status === 200 &&
      response.data.message === "Plan updated successfully"
    ) {
      payload.closeModal();
      thunkAPI.dispatch(getAllActionPlans());
    }
  }
);

export const updateIsOpenedForDoc = createAsyncThunk(
  "actionPlan/updateIsOpenedForDoc",
  async (payload, thunkAPI) => {
    let response = await axios.put(
      `${backendServerBaseURL}/api/v1/plan/update/${payload.actionPlanId}`,
      {
        name: payload.name,
        isOpened: payload.isOpened,
      }
    );

    console.log(response.data);
    console.log(payload);
    if (
      response.status === 200 &&
      response.data.message === "Plan updated successfully"
    ) {
      payload.closeModal();
      thunkAPI.dispatch(getAllActionPlans());
    }
  }
);

export const deleteActionPlan = createAsyncThunk(
  "admin/deleteActionPlan",
  async (payload, thunkAPI) => {
    let response = await axios.delete(
      `${backendServerBaseURL}/api/v1/plan/delete/${
        thunkAPI.getState().admin.selectedActionPlan._id
      }`
    );

    if (
      response.status === 200 &&
      response.data.message === "Plan deleted successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      payload.closeModal();
    }
  }
);

export const addUsersToActionPlan = createAsyncThunk(
  "admin/addUsersToActionPlan",
  async (payload, thunkAPI) => {
    let response = await axios.post(
      `${backendServerBaseURL}/api/v1/plan/addUser/${
        thunkAPI.getState().admin.selectedActionPlan._id
      }`,
      {
        users: payload.users.map((u) => u._id),
        copyTextAllowed: payload.copyTextAllowed,
      }
    );

    if (
      response.status === 200 &&
      response.data.message === "Users added successfully"
    ) {
      payload.closeModal();
      thunkAPI.dispatch(getAllActionPlans());
    }
  }
);

// Category
export const createcategory = createAsyncThunk(
  "admin/createcategory",
  async (payload, thunkAPI) => {
    let response = await axios.post(
      `${backendServerBaseURL}/api/v1/category/create`,
      {
        name: payload.name,
        plan: thunkAPI.getState().admin.selectedActionPlan._id,
      }
    );

    if (
      response.status === 200 &&
      response.data.message === "Category created successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      payload.closeModal();
      setTimeout(() => {
        console.log(`collapse_${response.data.category.plan}`);
        window.document
          .getElementsByClassName(`${response.data.category._id}`)[0]
          .classList.toggle("show");
      }, 500);
    }
  }
);

export const editcategory = createAsyncThunk(
  "admin/editcategory",
  async (payload, thunkAPI) => {
    let response = await axios.post(
      `${backendServerBaseURL}/api/v1/category/update/${
        thunkAPI.getState().admin.selectedCategory._id
      }`,
      {
        name: payload.name,
      }
    );

    console.log(response.data);
    console.log(payload);
    if (
      response.status === 200 &&
      response.data.message === "Category updated successfully"
    ) {
      payload.closeModal();
      thunkAPI.dispatch(getAllActionPlans());
    }
  }
);

export const deletecategory = createAsyncThunk(
  "admin/deletecategory",
  async (payload, thunkAPI) => {
    let response = await axios.delete(
      `${backendServerBaseURL}/api/v1/category/delete/${
        thunkAPI.getState().admin.selectedCategory._id
      }`
    );

    if (
      response.status === 200 &&
      response.data.message === "Category deleted successfully"
    ) {
      setTimeout(() => {
        console.log(`collapse_${response.data.category.plan}`);
        window.document
          .getElementsByClassName(`${response.data.category._id}`)[0]
          .classList.toggle("show");
      }, 500);

      thunkAPI.dispatch(getAllActionPlans());
      payload.closeModal();
    }
  }
);

export const updateIsOpenedForCategory = createAsyncThunk(
  "actionPlan/updateIsOpenedForCategory",
  async (payload, thunkAPI) => {
    let response = await axios.post(
      `${backendServerBaseURL}/api/v1/category/update/${payload.categoryId}`,
      {
        name: payload.name,
        isOpened: payload.isOpened,
      }
    );

    console.log(response.data);
    console.log(payload);
    if (
      response.status === 200 &&
      response.data.message === "Category updated successfully"
    ) {
      payload.closeModal();
      thunkAPI.dispatch(getAllActionPlans());
    }
  }
);

// Pointer
export const readPointer = createAsyncThunk(
  "admin/readPointer",
  async (payload, thunkAPI) => {
    let response = await axios.get(
      `${backendServerBaseURL}/api/v1/content/read/${payload.pointerId}`
    );

    if (
      response.status === 200 &&
      response.data.message === "Content fetched successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      thunkAPI.dispatch(updatesinglePointerInfo(response.data.content));
      payload.closeModal();
    }
  }
);

export const createpointer = createAsyncThunk(
  "admin/createpointer",
  async (payload, thunkAPI) => {
    let response = await axios.post(
      `${backendServerBaseURL}/api/v1/content/create`,
      {
        name: payload.name,
        plan: thunkAPI.getState().admin.selectedActionPlan._id,
        category: thunkAPI.getState().admin.selectedCategory._id,
      }
    );

    if (
      response.status === 200 &&
      response.data.message === "Content created successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      payload.closeModal();
    }
  }
);

export const editpointer = createAsyncThunk(
  "admin/editpointer",
  async (payload, thunkAPI) => {
    let response = await axios.put(
      `${backendServerBaseURL}/api/v1/content/update/${payload.pointerId}`,
      {
        name: payload.name,
        data: payload.data,
      }
    );

    console.log(response.data);
    console.log(payload);
    if (
      response.status === 200 &&
      response.data.message === "Content updated successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      if (payload.navigate) {
        payload.navigate("/action-plans");
      }
      payload.closeModal();
    }
  }
);

export const deletepointer = createAsyncThunk(
  "admin/deletepointer",
  async (payload, thunkAPI) => {
    let response = await axios.delete(
      `${backendServerBaseURL}/api/v1/content/delete/${
        thunkAPI.getState().admin.selectedPointer._id
      }`
    );

    if (
      response.status === 200 &&
      response.data.message === "Content deleted successfully"
    ) {
      thunkAPI.dispatch(getAllActionPlans());
      payload.closeModal();
    }
  }
);

export const updateIsOpenedForPointer = createAsyncThunk(
  "actionPlan/updateIsOpenedForPointer",
  async (payload, thunkAPI) => {
    let response = await axios.put(
      `${backendServerBaseURL}/api/v1/content/update/${payload.pointerId}`,
      {
        name: payload.name,
        data: payload.data,
        isOpened: payload.isOpened,
      }
    );

    console.log(response.data);
    console.log(payload);
    if (
      response.status === 200 &&
      response.data.message === "Content updated successfully"
    ) {
      payload.closeModal();
      thunkAPI.dispatch(getAllActionPlans());
    }
  }
);

// Feedback
export const readAllFeedbacks = createAsyncThunk(
  "admin/readAllFeedbacks",
  async (payload, thunkAPI) => {
    let response = await axios.get(
      `${backendServerBaseURL}/api/v1/feedback/read`
    );

    if (response.status === 200) {
      thunkAPI.dispatch(updateallFeedbacks(response.data));
    }
  }
);

export const resolveFeedbacks = createAsyncThunk(
  "admin/resolveFeedbacks",
  async (payload, thunkAPI) => {
    let response = await axios.put(
      `${backendServerBaseURL}/api/v1/feedback/resolve/${payload.feedbackId}`
    );

    if (response.status === 200) {
      thunkAPI.dispatch(readAllFeedbacks());
      payload.closeDialog();
    }
  }
);

// Analytics
export const readAnalyticsData = createAsyncThunk(
  "admin/readAnalyticsData",
  async (payload, thunkAPI) => {
    let response = await axios.get(
      `${backendServerBaseURL}/api/v1/analytics/readAdminAnalytics`,
      {
        params: { span: thunkAPI.getState().admin.span },
      }
    );

    if (response.status === 200) {
      thunkAPI.dispatch(updateanayticsData(response.data.users));
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateUsers: (state, action) => {
      state.users = action.payload;
    },
    updateselectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },

    updateActionPlans: (state, action) => {
      state.actionPlans = action.payload;
    },
    updateSelectedActionPlan: (state, action) => {
      state.selectedActionPlan = action.payload;
    },
    updateselectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    updateselectedPointer: (state, action) => {
      state.selectedPointer = action.payload;
    },
    updatesinglePointerInfo: (state, action) => {
      state.singlePointerInfo = action.payload;
    },
    updateallFeedbacks: (state, action) => {
      state.allFeedbacks = action.payload;
    },
    updateselectedFeedback: (state, action) => {
      state.selectedFeedback = action.payload;
    },

    updateanayticsData: (state, action) => {
      state.anayticsData = action.payload;
    },
    updatespan: (state, action) => {
      state.span = action.payload;
    },
  },
});

export const {
  updateUsers,
  updateselectedCustomer,
  updateActionPlans,
  updateSelectedActionPlan,
  updateselectedCategory,
  updateselectedPointer,
  updatesinglePointerInfo,
  updateallFeedbacks,
  updateselectedFeedback,

  updateanayticsData,
  updatespan,
} = adminSlice.actions;

export const selectUsers = (state) => state.admin.users;
export const selectselectedCustomer = (state) => state.admin.selectedCustomer;

export const selectActionPlans = (state) => state.admin.actionPlans;
export const selectSelectedActionPlan = (state) =>
  state.admin.selectedActionPlan;
export const selectselectedCategory = (state) => state.admin.selectedCategory;
export const selectselectedPointer = (state) => state.admin.selectedPointer;
export const selectsinglePointerInfo = (state) => state.admin.singlePointerInfo;

export const selectallFeedbacks = (state) => state.admin.allFeedbacks;
export const selectselectedFeedback = (state) => state.admin.selectedFeedback;

export const selectanayticsData = (state) => state.admin.anayticsData;
export const selectspan = (state) => state.admin.span;

export default adminSlice.reducer;
