import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, selectUsers, updateselectedCustomer } from "../redux/slices/adminSlice";
import DeleteCustomerDialog from "./DeleteCustomerDialog";
import DisableCustomerDialog from "./DisableCustomerDialog";
import EnableCustomerDialog from "./EnableCustomerDialog";
import NewCustomerDialog from "./NewCustomerDialog";
import { backendServerBaseURL, frontURL } from "../utils/backendServerBaseURL";

function UserManagement() {
  const [selectedMenu, setselectedMenu] = useState("All");
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const [resetRequestArray, updateresetRequestArray] = useState([]);
  const [resetUserLength, setresetUserLength] = useState(null); 


  const ProjectsMenus = [
    {
      name: "All",
    },
    {
      name: "Enabled",
    },
    {
      name: "Disabled",
    },
   
  ];

  const RightProjectsMenus = [
    {
      name: `Reset Requests (${resetUserLength})`
    }
  ];

  useEffect(() => {
    dispatch(getAllUsers());
    getAllResetRequests();
  }, [selectedMenu]);

  
  const getAllResetRequests = () => {

    const value = onGetAllResetRequests();
    console.log(value);

};


const onGetAllResetRequests = () => {
    let token = localStorage.getItem('accessToken');
     fetch(`${backendServerBaseURL}/api/v1/admin/reset-password-request`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + token
        },
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
            let allResetRequestsListNew = [];

            data.data.map((x) => (
                allResetRequestsListNew.push({
                    id: x._id,
                    name: x.firstName + '  ' + x.lastName,
                    joined: x.joined,
                    email: x.email,
                    credits: x.organization.initiallyAssignedCredits ? x.organization.initiallyAssignedCredits : '-' ,
                    remCredits: x.organization.credits ? x.organization.credits : '-',
                    topupCredits: x.organization.totalTopupCredits ? x.organization.totalTopupCredits : '-',
                    token: x.signupToken,
                    status: x.status, 
                    limit: x.limit,
                    resetPasswordToken: x.resetPasswordToken
                })
            ))
      
            updateresetRequestArray(Object.assign(allResetRequestsListNew))
            setresetUserLength(allResetRequestsListNew.length);         
        })
        .catch(error => {
            throw error
        })
}

 const onClickDiscard = (req) =>{
    let {id} = req
    const payload = {
        userId: id,
    };

   onClickDiscardRequests(payload);
}

const onClickDiscardRequests = (data) => {
    let token = localStorage.getItem('accessToken');
     fetch(`${backendServerBaseURL}/api/v1/admin/reset-password-request?userId=${data.userId}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json()
        })
        .then(data => {
          getAllResetRequests();
        })
        .catch(error => {
            throw error
        })
}

  const handleCopyLink = (index) => {
    const tempInput = document.createElement("input");
    tempInput.value = `${frontURL}/complete-profile/${index}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Display an alert indicating that the link has been copied
    const alertElement = document.createElement("div");
    alertElement.className = "alert alert-success mt-3";
    alertElement.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
        <path d="M7.5 1C3.91 1 1 3.91 1 7.5S3.91 14 7.5 14 14 11.09 14 7.5 11.09 1 7.5 1zm2.516 5.97a.53.53 0 0 1-.77 0L6.47 9.47l-1.47-1.47a.53.53 0 1 1 .76-.76L7 8.24l2.24-2.23a.53.53 0 1 1 .76.76l-2.23 2.23 2.23 2.23a.53.53 0 0 1 0 .77z"/>
      </svg>
      Link Copied!
    `;
    // alertElement.style.cssText = `width:fit-content;margin:auto;z-index:1000; `;
    document.getElementById("alert-container").appendChild(alertElement); // Append the alert element to a container div
    setTimeout(() => {
      alertElement.remove();
    }, 3000);
  };



  return (
    <div className="page-body">
      <div className="d-flex">
        <div>
          <h1 className="mb-1">User Management</h1>
          <p className="text-secondary">Add/Remove your Customers</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            <button
              type="button"
              className="btn btn-primary"
              style={{ minWidth: "10rem" }}
              data-bs-toggle="modal"
              data-bs-target="#newCustomerModal"
              onClick={() => {
                dispatch(updateselectedCustomer(null));
              }}
            >
              + Add Customer
            </button>
          </div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 regular-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={
                    selectedMenu === menu.name
                      ? "text-center border-bottom border-primary border-3"
                      : "text-center pb-1"
                  }
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {users &&
        users.filter((u) => {
          if (selectedMenu === "Enabled") return u.status === "enabled";
          if (selectedMenu === "Disabled") return u.status === "disabled";
          return true;
        }).length === 0 && (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="vstack gap-3 text-center">
              <img
                src="/static/illustrations/no-users-found.svg"
                alt=""
                height="200px"
              />
              <h2 className="body1 regular-weight">No Users Found</h2>

              <div>
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#newCustomerModal"
                  onClick={() => {
                    dispatch(updateselectedCustomer(null));
                  }}
                >
                  + Add Customer
                </button>
              </div>
            </div>
          </div>
        )}

      {users &&
        users.filter((u) => {
          if (selectedMenu === "Enabled") return u.status === "enabled";
          if (selectedMenu === "Disabled") return u.status === "disabled";
          return true;
        }).length !== 0 &&
        selectedMenu !== `Reset Requests (${resetUserLength})` && (
          <table className="table table-borderless mt-2 custom-table-sm">
            <thead className="border-none">
              <tr>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Name
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Joined
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Organization
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Email
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  No. of users
                </td>
                <td scope="col" className="text-secondary body3 regular-weight">
                  Token
                </td>

                <td scope="col" className="text-secondary body3 regular-weight">
                  Account status
                </td>
                <td
                  scope="col"
                  className="text-secondary body3 regular-weight"
                ></td>
              </tr>
            </thead>
            <tbody>
              {users &&
                users
                  .filter((u) => {
                    if (selectedMenu === "Enabled")
                      return u.status === "enabled";
                    if (selectedMenu === "Disabled")
                      return u.status === "disabled";
                    return true;
                  })
                  .map((user) => {
                    return (
                      <tr className="border bg-white">
                        <td className="body3 regular-weight align-middle">{`${user.firstName} ${user.lastName}`}</td>
                        <td className="body3 regular-weight align-middle">
                          {user.joined ? user.joined : "-"}
                        </td>
                        <td className="body3 regular-weight align-middle">
                          {user.organization ? user.organization : "-"}
                        </td>

                        <td className="body3 regular-weight align-middle">
                          {user.email}
                        </td>
                        <td className="body3 regular-weight align-middle">
                          {user.clients} / {user.limit}
                        </td>
                        {/* <td className="body3 regular-weight align-middle">{user.token}</td> */}
                        {user.token ? (
                          <td
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            className="body3 regular-weight"
                            onClick={() => handleCopyLink(user.token)}
                          >
                            Copy Link
                          </td>
                        ) : (
                          <td className="body3 regular-weight">-</td>
                        )}
                        <td className="body3 regular-weight align-middle">
                          <span
                            class={
                              user.status === "invited"
                                ? "blue-chip"
                                : user.status === "enabled"
                                ? "green-chip"
                                : "gray-chip"
                            }
                            style={{ textTransform: "capitalize" }}
                          >
                            {user.status}
                          </span>
                        </td>

                        <td className="body3 regular-weight align-middle">
                          <div class="dropdown">
                            <i
                              class="bi bi-three-dots-vertical cp"
                              data-bs-toggle="dropdown"
                            ></i>

                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  className="dropdown-item body3 regular-weight"
                                  data-bs-toggle="modal"
                                  data-bs-target="#newCustomerModal"
                                  onClick={() => {
                                    dispatch(updateselectedCustomer(user));
                                  }}
                                >
                                  Edit Account
                                </a>
                              </li>
                              {user.status != "invited" &&
                                user.status == "enabled" && (
                                  <li>
                                    <a
                                      className="dropdown-item body3 regular-weight"
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#disableCustomerDialog"
                                      onClick={() => {
                                        dispatch(updateselectedCustomer(user));
                                      }}
                                    >
                                      Disable Account
                                    </a>
                                  </li>
                                )}

                              {user.status == "invited" && (
                                <li>
                                  <a
                                    className="dropdown-item body3 regular-weight"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteCustomerDialog"
                                    onClick={() => {
                                      dispatch(updateselectedCustomer(user));
                                    }}
                                  >
                                    Delete Account
                                  </a>
                                </li>
                              )}

                              {user.status != "invited" &&
                                user.status == "disabled" && (
                                  <li>
                                    <a
                                      className="dropdown-item body3 regular-weight"
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#EnableCustomerDialog"
                                      onClick={() => {
                                        dispatch(updateselectedCustomer(user));
                                      }}
                                    >
                                      Enable Account
                                    </a>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        )}
      <div
        id="alert-container"
        className="position-fixed top-0 start-50 translate-middle-x"
        style={{ zIndex: "1000" }}
      ></div>

      {resetRequestArray.length === 0 &&
        selectedMenu === `Reset Requests (${resetUserLength})` && (
          <div className="align-items-center justify-content-center mt-5">
            <div className="vstack gap-3 text-center">
              <img
                src="/static/illustrations/no-users-found.svg"
                alt=""
                height="200px"
              />
              <h2 className="body1 regular-weight">No Users Found</h2>

              <div></div>
            </div>
          </div>
        )}

      {resetRequestArray.length > 0 &&
      selectedMenu === `Reset Requests (${resetUserLength})` ? (
        <table className="table table-borderless mt-2 custom-table-sm-2">
          <thead className="border-none">
            <tr>
              <td scope="col" className="text-secondary body3 regular-weight">
                Name
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Joined
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Organization
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Email
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                No. of users
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Token
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Reset Token
              </td>
              <td scope="col" className="text-secondary body3 regular-weight">
                Account status
              </td>
              <td
                scope="col"
                className="text-secondary body3 regular-weight"
              ></td>
            </tr>
          </thead>
          <tbody>
            {resetRequestArray.map((req, index) => {
              return (
                <tr key={req.id} className="border bg-white">
                  <td className="body3 regular-weight align-middle">
                    {req.name}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    {req.joined ? req.joined : "-"}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    {req.organization ? req.organization : "-"}
                  </td>

                  <td className="body3 regular-weight align-middle">
                    {req.email}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    {"0"} / {req.limit}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    {req.token ? req.token : "-"}
                  </td>
                  <td className="body3 regular-weight align-middle">
                    {req.resetPasswordToken}
                  </td>

                  <td className="body3 regular-weight align-middle">
                    <span
                      class={
                        req.status === "invited"
                          ? "blue-chip"
                          : req.status === "enabled"
                          ? "green-chip"
                          : "gray-chip"
                      }
                      style={{ textTransform: "capitalize" }}
                    >
                      {req.status}
                    </span>
                  </td>

                  <td className="body3 regular-weight align-middle">
                    <div class="dropdown">
                      <i
                        class="bi bi-three-dots-vertical cp"
                        data-bs-toggle="dropdown"
                      ></i>

                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item body3 regular-weight"
                            onClick={() => {
                              onClickDiscard(req);
                            }}
                          >
                            Discard Account
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div></div>
      )}

      <NewCustomerDialog />
      <DisableCustomerDialog />
      <DeleteCustomerDialog />
      <EnableCustomerDialog />
    </div>
  );
}

export default UserManagement;
