import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, readAnalyticsData, selectanayticsData, selectUsers, updatespan } from "../redux/slices/adminSlice";
import { formatTime } from "../utils/formatTime";

function Analytics() {
  const [selectedMenu, setselectedMenu] = useState("All");
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const analyticsData = useSelector(selectanayticsData);
  const [csvData, setcsvData] = useState([]);

  const ProjectsMenus = [];

  const RightProjectsMenus = [];

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(readAnalyticsData());
  }, [selectedMenu]);

  useEffect(() => {
    let tempCSVData = [
      [
        "User",
        "Status",
        "Created",
        "Updated",
        "Goals",
        "Ideas",
        "Tests",
        "Worked",
        "Didn’t work",
        "Inconclusive",
        "Total",
        "Users",
        "	W.A.U",
        "Active %",
      ],
    ];
    analyticsData.map((singleUser) => {
      tempCSVData.push([
        singleUser?.email,
        singleUser?.status,
        formatTime(singleUser.createdAt),
        formatTime(singleUser.updatedAt),
        singleUser?.goals,
        singleUser?.ideas,
        singleUser?.tests,

        singleUser?.workedLearnings,
        singleUser?.notWorkedLearnings,
        singleUser?.inconclusiveLearnings,
        singleUser?.totalLearnings,

        singleUser?.users,
        singleUser?.status,
        singleUser?.status,
      ]);
    });

    setcsvData(tempCSVData);
    console.log(csvData);
  }, [analyticsData]);

  return (
    <div className="page-body">
      <div className="d-flex">
        <div>
          <h1 className="mb-1">Analytics</h1>
          <p className="text-secondary">{analyticsData?.length == 1 ? "1 Customer" : `${analyticsData?.length} Customers`}</p>
        </div>

        <div className="flex-fill d-flex flex-row-reverse">
          <div className="hstack gap-3" style={{ padding: 0 }}>
            <select
              className="form-select"
              onChange={(e) => {
                dispatch(updatespan(e.target.value));
                dispatch(readAnalyticsData());
              }}
            >
              <option value="1week">1 Week</option>
              <option value="2weeks">2 Weeks</option>
              <option value="1month">1 Month</option>
              <option value="3months">3 Months</option>
              <option value="6months">6 Months</option>
              <option value="12months">12 Months</option>
            </select>
            <CSVLink data={csvData} filename={"Analytics.csv"}>
              <button type="button" className="btn btn-outline-primary" style={{ minWidth: "10rem" }}>
                Export as CSV
              </button>
            </CSVLink>
          </div>
        </div>
      </div>

      <div className="border-bottom mt-3">
        <div className="flex-fill d-flex align-items-center">
          {ProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 semi-bold-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}

          <div className="flex-fill ml-auto"></div>

          {RightProjectsMenus.map((menu) => {
            return (
              <div
                style={{ textDecoration: "none" }}
                className="text-dark body3 semi-bold-weight cp"
                onClick={() => {
                  setselectedMenu(menu.name);
                }}
              >
                <div
                  className={selectedMenu === menu.name ? "text-center border-bottom border-primary border-3" : "text-center pb-1"}
                  style={{ minWidth: "7rem" }}
                >
                  <p className="mb-1">{menu.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <table className="table table-borderless mt-2 custom-table-sm">
        <thead className="border-none">
          <tr style={{ backgroundColor: "#F3F3F3" }}>
            <td scope="col" colSpan={7} className="text-secondary body semi-bold-weight border">
              Workspace
            </td>
            <td scope="col" colSpan={4} className="text-secondary body3 semi-bold-weight border">
              Learnings
            </td>
            <td scope="col" colSpan={3} className="text-secondary body3 semi-bold-weight border">
              Activity
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="border" style={{ backgroundColor: "#F3F3F3" }}>
            <td className="body3 semi-bold-weight align-middle border">User</td>
            <td className="body3 semi-bold-weight align-middle border">Status</td>
            <td className="body3 semi-bold-weight align-middle border">Created</td>
            <td className="body3 semi-bold-weight align-middle border">Updated</td>
            <td className="body3 semi-bold-weight align-middle border">Goals</td>
            <td className="body3 semi-bold-weight align-middle border">Ideas</td>
            <td className="body3 semi-bold-weight align-middle border">Tests</td>

            <td className="body3 semi-bold-weight align-middle border">Worked</td>
            <td className="body3 semi-bold-weight align-middle border">Didn’t work</td>
            <td className="body3 semi-bold-weight align-middle border">Inconclusive</td>
            <td className="body3 semi-bold-weight align-middle border">Total</td>

            <td className="body3 semi-bold-weight align-middle border">Users</td>
            <td className="body3 semi-bold-weight align-middle border">W.A.U</td>
            <td className="body3 semi-bold-weight align-middle border">Active %</td>
          </tr>

          {analyticsData.map((singleUser) => {
            return (
              <tr className="border bg-white">
                <td className="body3 regular-weight align-middle border">{singleUser?.email}</td>
                <td className="body3 regular-weight align-middle border">
                  <div
                    className={`d-flex justify-content-between align-items-center rounded ${singleUser.status === "disabled" && "gray-chip"} ${
                      singleUser.status === "enabled" && "green-chip"
                    }`}
                    style={{ maxWidth: "6.3rem" }}
                  >
                    <div className="w-100 text-center">
                      <p className="m-0">{singleUser.status}</p>
                    </div>
                  </div>
                </td>
                <td className="body3 regular-weight align-middle border">{formatTime(singleUser.createdAt)}</td>
                <td className="body3 regular-weight align-middle border">{formatTime(singleUser.updatedAt)}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.goals}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.ideas}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.tests}</td>
                {/* TODO: Update backend to get following data */}
                <td className="body3 regular-weight align-middle border">{singleUser.workedLearnings}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.notWorkedLearnings}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.inconclusiveLearnings}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.totalLearnings}</td>

                <td className="body3 regular-weight align-middle border">{singleUser.users}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.status}</td>
                <td className="body3 regular-weight align-middle border">{singleUser.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Analytics;
