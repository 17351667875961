import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Page404 from "../pages/General/Page404";
import Page500 from "../pages/General/Page500";
import Login from "../pages/Login";
import UserManagement from "../pages/UserManagement";
import { useLocation } from "react-router-dom";
import ActionPlans from "../pages/ActionPlans/ActionPlans";
import Analytics from "../pages/Analytics";
import Feedback from "../pages/Feedback";
import SingleActionPlan from "../pages/ActionPlans/SingleActionPlan";

function RequireAuth({ children }) {
  const accessToken = localStorage.getItem("accessToken", null);
  const location = useLocation();

  if (accessToken == null || accessToken === undefined) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Login />,
    },

    {
      path: "",
      element: (
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      ),
      children: [
        {
          path: "user-management",
          element: <UserManagement />,
        },
        {
          path: "action-plans",
          element: <ActionPlans />,
        },

        {
          path: "action-plans/:actionPlanId/:categoryId/:contentId",
          element: <SingleActionPlan />,
        },
        {
          path: "analytics",
          element: <Analytics />,
        },
        {
          path: "feedback",
          element: <Feedback />,
        },
      ],
    },

    { path: "404", element: <Page404 /> },
    { path: "500", element: <Page500 /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
