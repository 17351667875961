import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { Form, FormikProvider } from "formik";
import { createUser, selectselectedCustomer, updateUser } from "../redux/slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../components/common/LoadingButton";
import { useRef } from "react";
import { useEffect } from "react";

function NewCustomerDialog() {
  const dispatch = useDispatch();
  const closeDialogRef = useRef();
  const selectedCustomer = useSelector(selectselectedCustomer);

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      noOfUserAccess: "",
      firstName: "",
      lastName: "",
      organization: "",
    },
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Email must be a valid email address").required("Email is required"),
      noOfUserAccess: Yup.number().required("No of User Access is required"),
      firstName: Yup.string(),
      lastName: Yup.string(),
      organization: Yup.string(),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      console.log(values);
      setSubmitting(true);
      if (selectedCustomer) {
        console.log(selectedCustomer);
        await dispatch(updateUser({ ...values, setErrors, closeDialog, resetForm, userId: selectedCustomer.id }));
      } else {
        await dispatch(createUser({ ...values, setErrors, closeDialog, resetForm }));
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (selectedCustomer) {
      formik.setValues({
        email: selectedCustomer.email,
        noOfUserAccess: selectedCustomer.limit,
        firstName: selectedCustomer.firstName,
        lastName: selectedCustomer.lastName,
        organization: selectedCustomer.organization,
      });
    } else {
      formik.setValues({
        email: "",
        noOfUserAccess: "",
        firstName: "",
        lastName: "",
        organization: "",
      });
    }
  }, [selectedCustomer]);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, resetForm } = formik;

  return (
    <>
      <div className="modal fade" id="newCustomerModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <h2>{selectedCustomer ? "Edit" : "New"} Customer</h2>
                  {!selectedCustomer && <p style={{ marginBottom: "24px" }}>Add customer to your product</p>}

                  {/* Email */}
                  <div className="form-field mt-2">
                    <label className="form-label">Email</label>
                    <input type={"email"} {...getFieldProps("email")} className="form-control form-control-lg" placeholder="Email Address" />

                    <span className="invalid-feedback" style={{ display: Boolean(touched.email && errors.email) ? "block" : "none" }}>
                      {errors.email}
                    </span>
                  </div>

                  {/* No. of User Access */}
                  <div className="form-field">
                    <label className="form-label">No. of User Access</label>
                    <input
                      type={"number"}
                      {...getFieldProps("noOfUserAccess")}
                      className="form-control form-control-lg"
                      placeholder="Enter number of users"
                    />
                    <span
                      className="invalid-feedback"
                      style={{ display: Boolean(touched.noOfUserAccess && errors.noOfUserAccess) ? "block" : "none" }}
                    >
                      {errors.noOfUserAccess}
                    </span>
                  </div>

                  {/* First name and Last name */}
                  <div className="row">
                    {/* First Name (optional) */}
                    <div className="form-field col-6">
                      <label className="form-label">First Name (optional)</label>
                      <input type={"text"} {...getFieldProps("firstName")} className="form-control form-control-lg" placeholder="Enter First Name" />
                      <span className="invalid-feedback" style={{ display: Boolean(touched.firstName && errors.firstName) ? "block" : "none" }}>
                        {errors.firstName}
                      </span>
                    </div>

                    {/* Last Name (optional) */}
                    <div className="form-field col-6">
                      <label className="form-label">Last Name (optional)</label>
                      <input type={"text"} {...getFieldProps("lastName")} className="form-control form-control-lg" placeholder="Enter Last Name" />
                      <span className="invalid-feedback" style={{ display: Boolean(touched.lastName && errors.lastName) ? "block" : "none" }}>
                        {errors.lastName}
                      </span>
                    </div>
                  </div>

                  {/* Company (optional) */}
                  <div className="form-field">
                    <label className="form-label">Company (optional)</label>
                    <input type={"text"} {...getFieldProps("organization")} className="form-control form-control-lg" placeholder="Enter Company" />
                    <span className="text-secondary">User will be asked to finish the profile</span>
                    <span className="invalid-feedback" style={{ display: Boolean(touched.organization && errors.organization) ? "block" : "none" }}>
                      {errors.organization}
                    </span>
                  </div>

                  {/* Errors from server */}
                  {errors.afterSubmit && (
                    <div className="alert alert-danger" role="alert">
                      {errors.afterSubmit}
                    </div>
                  )}

                  <div className="hstack gap-2 d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-lg btn-outline-danger"
                      data-bs-dismiss="modal"
                      ref={closeDialogRef}
                      onClick={() => {
                        resetForm();
                      }}
                    >
                      Close
                    </button>
                    <LoadingButton loading={isSubmitting} type="submit" class="btn btn-lg btn-primary">
                      {selectedCustomer ? "Update" : "Add Customer"}
                    </LoadingButton>
                  </div>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewCustomerDialog;
