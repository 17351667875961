import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { ReactSortable } from "react-sortablejs";
import CreateNewActionPlanDialog from "./CreateNewActionPlanDialog";
import ManageActionPlanAccessDialog from "./ManageActionPlanAccessDialog";
import DeleteActionPlanDialog from "./DeleteActionPlanDialog";
import CreateNewCategoryDialog from "./CreateNewCategoryDialog";
import DeleteCategoryDialog from "./DeleteCategoryDialog";
import CreateNewPointerDialog from "./CreateNewPointerDialog";
import DeletePointerDialog from "./DeletePointerDialog";
import {
  getAllActionPlans,
  selectActionPlans,
  updateSelectedActionPlan,
  updateselectedCategory,
  updateIsOpenedForPointer,
  updateIsOpenedForCategory,
  updateIsOpenedForDoc,
  updateselectedPointer,
} from "../../redux/slices/adminSlice";
import { useSelector } from "react-redux";
import { Collapse } from "react-collapse";
import "./AdminPlans.css";

function ActionPlans() {
  const [selectedMenu, setselectedMenu] = useState("Internal");
  // const actionPlans = [];
  const actionPlans = useSelector(selectActionPlans);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ProjectsMenus = [];

  const RightProjectsMenus = [];

  let internalActionPlanSortData = Object.assign(actionPlans);

  const [allActionPlans, setallActionPlans] = useState([
    internalActionPlanSortData,
  ]);
  console.log("allActionPlans :>> ", allActionPlans);

  useEffect(() => {
    // TODO:
    dispatch(getAllActionPlans());
  }, [selectedMenu]);

  useEffect(() => {
    // TODO:
    if (actionPlans) {
      setallActionPlans(
        actionPlans.map((plan) => {
          let tempPlan = { ...plan };
          tempPlan.category = tempPlan.category.map((cat) => {
            let tempcat = { ...cat };
            tempcat.content = tempcat.content.map((cat) => {
              return { ...cat };
            });
            return tempcat;
          });
          return tempPlan;
        })
      );
    }
  }, [actionPlans]);

  const setIsOpenedForDoc = (id, isOpened) => {
    let temp = allActionPlans.map((plan) => {
      if (plan._id === id) {
        plan.isOpened = isOpened;

        dispatch(
          updateIsOpenedForDoc({
            name: plan.name,
            isOpened: isOpened,
            actionPlanId: id,
          })
        );
      }
      return plan;
    });
    setallActionPlans(temp);
  };

  const setIsOpenedForCategory = (id, isOpened) => {
    let temp = allActionPlans.map((plan) => {
      plan.category = plan.category.map((cat) => {
        if (cat._id === id) {
          cat.isOpened = isOpened;

          dispatch(
            updateIsOpenedForCategory({
              name: cat.name,
              isOpened: isOpened,
              categoryId: id,
            })
          );
        }
        return cat;
      });
      return plan;
    });
    setallActionPlans(temp);
  };

  const setIsOpenedForContent = (id, isOpened) => {
    let temp = allActionPlans.map((plan) => {
      plan.category = plan.category.map((cat) => {
        cat.content = cat.content.map((content) => {
          if (content._id === id) {
            content.isOpened = isOpened;

            dispatch(
              updateIsOpenedForPointer({
                name: content.name,
                isOpened: isOpened,
                data: content.data,
                pointerId: id,
              })
            );
          }
          return content;
        });
        return cat;
      });
      return plan;
    });
    setallActionPlans(temp);
  };

  const setAllIsOpenedToTrue = () => {
    let temp = allActionPlans.map((plan) => {
      plan.isOpened = true;
      plan.category = plan.category.map((cat) => {
        cat.isOpened = true;
        cat.content = cat.content.map((content) => {
          content.isOpened = true;
          return content;
        });
        return cat;
      });
      return plan;
    });
    setallActionPlans(temp);
  };

  const setAllIsOpenedToFalse = () => {
    let temp = allActionPlans.map((plan) => {
      plan.isOpened = false;
      plan.category = plan.category.map((cat) => {
        cat.isOpened = false;
        cat.content = cat.content.map((content) => {
          content.isOpened = false;
          return content;
        });
        return cat;
      });
      return plan;
    });
    setallActionPlans(temp);
  };

  return (
    <>
      <div className="page-body">
        <div className="d-flex">
          <div>
            <h1 className="mb-1">Action Plans</h1>
            <p className="text-secondary">All your process at one place</p>
          </div>

          <div className="flex-fill d-flex flex-row-reverse">
            <div className="hstack gap-3" style={{ padding: 0 }}>
              {selectedMenu === "Internal" && (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ minWidth: "10rem" }}
                    data-bs-toggle="modal"
                    data-bs-target="#createActionPlanModal"
                    onClick={() => {
                      // dispatch(updateSelectedActionPlan(null));
                    }}
                  >
                    + New Plan
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="border-bottom mt-3">
          <div className="flex-fill d-flex align-items-center">
            {ProjectsMenus.map((menu) => {
              return (
                <div
                  style={{ textDecoration: "none" }}
                  className="text-dark body3 regular-weight cp"
                  onClick={() => {
                    setselectedMenu(menu.name);
                  }}
                >
                  <div
                    className={
                      selectedMenu === menu.name
                        ? "text-center border-bottom border-primary border-3"
                        : "text-center pb-1"
                    }
                    style={{ minWidth: "7rem" }}
                  >
                    <p className="mb-1">{menu.name}</p>
                  </div>
                </div>
              );
            })}

            <div className="flex-fill ml-auto"></div>

            {RightProjectsMenus.map((menu) => {
              return (
                <div
                  style={{ textDecoration: "none" }}
                  className="text-dark body3 regular-weight cp"
                  onClick={() => {
                    setselectedMenu(menu.name);
                  }}
                >
                  <div
                    className={
                      selectedMenu === menu.name
                        ? "text-center border-bottom border-primary border-3"
                        : "text-center pb-1"
                    }
                    style={{ minWidth: "7rem" }}
                  >
                    <p className="mb-1">{menu.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {actionPlans.length === 0 && (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <div className="vstack gap-3 text-center">
              <img
                src="/static/illustrations/no-projects-found.svg"
                alt=""
                height="200px"
              />
              <h2 className="body1 regular-weight">No Plans Found</h2>
              {selectedMenu === "Internal" && (
                <div>
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#createActionPlanModal"
                    onClick={() => {
                      // dispatch(updateSelectedActionPlan(null));
                    }}
                  >
                    + New Plan
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <>
          <button
            className="btn btn-primary mt-3 mb-3"
            style={{ marginRight: "0.6rem" }}
            onClick={() => {
              setAllIsOpenedToFalse();
            }}
          >
            Collapse All Actions
          </button>

          <button
            className="btn btn-primary mt-3 mb-3"
            onClick={() => {
              setAllIsOpenedToTrue();
            }}
          >
            Expand All Actions
          </button>

          <div className="faq_main_wrap mt-2">
            <div className="bs-example">
              <div className="accordion" id="accordionExample">
                {allActionPlans?.map((item, index) => (
                  <div
                    className="mb-3"
                    key={`actionplan_${index}`}
                    onClick={() => {
                      setIsOpenedForDoc(item._id, !item.isOpened);
                    }}
                  >
                    <div className="parent-card-header draggableItem">
                      <button type="button" className="btn">
                        <span className="hoverLink fw-bold">
                          {/* <img src="/static/images/dots.svg" alt="a" className="img-fluid" />  */}

                          <span>{item.name}</span>
                        </span>
                        <span className="title-6">
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <div class="dropdown">
                              <i
                                class="bi bi-three-dots-vertical custom-more-icon-hover-effect"
                                data-bs-toggle="dropdown"
                                style={{
                                  padding: "0.5rem",
                                }}
                              ></i>

                              <ul class="dropdown-menu">
                                <li className="border-bottom p-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#createActionPlanModal"
                                    class="dropdown-item"
                                    onClick={() => {
                                      dispatch(updateSelectedActionPlan(item));
                                    }}
                                  >
                                    Edit Action Plan
                                  </a>
                                </li>
                                <li className="border-bottom p-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#ManageActionPlanAccessDialog"
                                    class="dropdown-item"
                                    onClick={() => {
                                      dispatch(updateSelectedActionPlan(item));
                                    }}
                                  >
                                    Manage Access
                                  </a>
                                </li>
                                <li className="border-bottom p-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#DeleteActionPlanDialog"
                                    class="dropdown-item"
                                    onClick={() => {
                                      dispatch(updateSelectedActionPlan(item));
                                    }}
                                  >
                                    Delete Action Plan
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </span>

                          {/* <span>
                              <div style={{ marginLeft: "1rem" }}>
                                <i className="fa fa-angle-right"> </i>
                              </div>
                            </span> */}
                        </span>
                      </button>
                    </div>

                    <Collapse isOpened={item.isOpened}>
                      {item?.category?.map((singleCategory, index) => (
                        <div
                          style={{ marginLeft: "1.3rem" }}
                          key={`cat_${singleCategory._id}`}
                          onClick={() => {
                            const classList = document.getElementById(
                              `collapseIcon_${singleCategory._id}`
                            ).classList;
                            console.log(classList);
                            if (classList.contains("fa-plus")) {
                              document
                                .getElementById(
                                  `collapseIcon_${singleCategory._id}`
                                )
                                .classList.remove("fa-plus");
                              document
                                .getElementById(
                                  `collapseIcon_${singleCategory._id}`
                                )
                                .classList.add("fa-minus");
                            } else {
                              document
                                .getElementById(
                                  `collapseIcon_${singleCategory._id}`
                                )
                                .classList.remove("fa-minus");
                              document
                                .getElementById(
                                  `collapseIcon_${singleCategory._id}`
                                )
                                .classList.add("fa-plus");
                            }
                          }}
                        >
                          <div
                            className="parent-card-body"
                            onClick={(e) => {
                              e.stopPropagation();

                              setIsOpenedForCategory(
                                singleCategory._id,
                                !singleCategory.isOpened
                              );
                            }}
                          >
                            <div className="faq_txt_wrap">
                              <div className="bs-example">
                                <div className="accordion">
                                  <div className="card-inner">
                                    <div
                                      className="card-header-inner"
                                      id="headingOnes"
                                    >
                                      <button type="button" className="btn">
                                        <span className="hoverLink fw-bold">
                                          <span>{singleCategory.name}</span>
                                        </span>

                                        <span
                                          className="title-6"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }}
                                        >
                                          <div class="dropdown">
                                            <i
                                              class="bi bi-three-dots-vertical custom-more-icon-hover-effect"
                                              data-bs-toggle="dropdown"
                                              style={{
                                                padding: "0.5rem",
                                              }}
                                            ></i>

                                            <ul class="dropdown-menu">
                                              <li className="border-bottom p-0">
                                                <a
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#CreateNewCategoryDialog"
                                                  class="dropdown-item"
                                                  onClick={() => {
                                                    dispatch(
                                                      updateSelectedActionPlan(
                                                        item
                                                      )
                                                    );
                                                    dispatch(
                                                      updateselectedCategory(
                                                        singleCategory
                                                      )
                                                    );
                                                  }}
                                                >
                                                  Edit Category
                                                </a>
                                              </li>

                                              <li className="border-bottom p-0">
                                                <a
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#DeleteCategoryDialog"
                                                  class="dropdown-item"
                                                  onClick={() => {
                                                    dispatch(
                                                      updateSelectedActionPlan(
                                                        item
                                                      )
                                                    );
                                                    dispatch(
                                                      updateselectedCategory(
                                                        singleCategory
                                                      )
                                                    );
                                                  }}
                                                >
                                                  Delete Category
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </span>
                                      </button>
                                    </div>

                                    <Collapse
                                      isOpened={singleCategory.isOpened}
                                    >
                                      <div
                                        aria-labelledby="headingOnes"
                                        style={{ marginLeft: "1.3rem" }}
                                      >
                                        <div className="card-body-inner">
                                          <div className="pointer-main-wrap">
                                            {singleCategory.content.map(
                                              (singleContent) => (
                                                <div
                                                  className="card-header-inner"
                                                  key={`content_${item._id}`}
                                                  onClick={(e) => {
                                                    e.stopPropagation();

                                                    setIsOpenedForContent(
                                                      singleContent._id,
                                                      !singleContent.isOpened
                                                    );
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn"
                                                    disabled={false}
                                                  >
                                                    <span
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      <span className="hoverLink fw-bold">
                                                        <span>
                                                          {singleContent.name}
                                                        </span>
                                                      </span>

                                                      <Collapse
                                                        isOpened={
                                                          singleContent.isOpened
                                                        }
                                                      >
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              singleContent.data,
                                                          }}
                                                        ></div>
                                                      </Collapse>
                                                    </span>
                                                    <span
                                                      className="title-6"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                      }}
                                                    >
                                                      <div class="dropdown">
                                                        <i
                                                          class="bi bi-three-dots-vertical custom-more-icon-hover-effect"
                                                          data-bs-toggle="dropdown"
                                                          style={{
                                                            padding: "0.5rem",
                                                          }}
                                                        ></i>

                                                        <ul class="dropdown-menu">
                                                          <li className="border-bottom p-0">
                                                            <a
                                                              data-bs-toggle="modal"
                                                              data-bs-target="#CreateNewPointerDialog"
                                                              class="dropdown-item"
                                                              onClick={() => {
                                                                dispatch(
                                                                  updateSelectedActionPlan(
                                                                    item
                                                                  )
                                                                );
                                                                dispatch(
                                                                  updateselectedCategory(
                                                                    singleCategory
                                                                  )
                                                                );
                                                                dispatch(
                                                                  updateselectedPointer(
                                                                    singleContent
                                                                  )
                                                                );
                                                              }}
                                                            >
                                                              Edit pointer
                                                            </a>
                                                          </li>

                                                          <li className="border-bottom p-0">
                                                            <a
                                                              class="dropdown-item"
                                                              onClick={() => {
                                                                localStorage.setItem(
                                                                  "selected-content-name",
                                                                  singleContent.name
                                                                );
                                                                navigate(
                                                                  `/action-plans/${item._id}/${singleCategory._id}/${singleContent._id}`
                                                                );
                                                              }}
                                                            >
                                                              Edit data
                                                            </a>
                                                          </li>

                                                          <li className="border-bottom p-0">
                                                            <a
                                                              data-bs-toggle="modal"
                                                              data-bs-target="#DeletePointerDialog"
                                                              class="dropdown-item"
                                                              onClick={() => {
                                                                dispatch(
                                                                  updateSelectedActionPlan(
                                                                    item
                                                                  )
                                                                );
                                                                dispatch(
                                                                  updateselectedCategory(
                                                                    singleCategory
                                                                  )
                                                                );
                                                                dispatch(
                                                                  updateselectedPointer(
                                                                    singleContent
                                                                  )
                                                                );
                                                              }}
                                                            >
                                                              Delete
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </span>
                                                  </button>
                                                </div>
                                              )
                                            )}
                                          </div>

                                          <div
                                            style={{
                                              marginBottom: "0.5rem",
                                              marginTop: "0.5rem",
                                              cursor: "pointer",
                                            }}
                                            className="text-primary"
                                          >
                                            <a
                                              data-bs-toggle="modal"
                                              data-bs-target="#CreateNewPointerDialog"
                                              onClick={() => {
                                                dispatch(
                                                  updateSelectedActionPlan(item)
                                                );
                                                dispatch(
                                                  updateselectedCategory(
                                                    singleCategory
                                                  )
                                                );
                                              }}
                                            >
                                              Add Pointer{" "}
                                              <i className="fa fa-plus" />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>

                                  {index + 1 == item?.category?.length && (
                                    <div>
                                      <button
                                        style={{ marginRight: "1.3rem" }}
                                        type="button"
                                        class="btn btn-outline-primary w-100 mt-2"
                                        data-bs-toggle="modal"
                                        data-bs-target="#CreateNewCategoryDialog"
                                        onClick={() => {
                                          dispatch(
                                            updateSelectedActionPlan(item)
                                          );
                                          dispatch(
                                            updateselectedCategory(null)
                                          );
                                        }}
                                        disabled={false}
                                      >
                                        Add a category +
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {item?.category?.length == 0 && (
                        <div
                          id={`collapse_${item._id}`}
                          style={{ marginLeft: "1.3rem" }}
                        >
                          <button
                            style={{ marginRight: "1.3rem" }}
                            type="button"
                            class="btn btn-outline-primary w-100 mt-2"
                            data-bs-toggle="modal"
                            data-bs-target="#CreateNewCategoryDialog"
                            onClick={() => {
                              dispatch(updateSelectedActionPlan(item));
                              dispatch(updateselectedCategory(null));
                            }}
                          >
                            Add a category +
                          </button>
                        </div>
                      )}
                    </Collapse>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      </div>

      <CreateNewActionPlanDialog />
      <ManageActionPlanAccessDialog />
      <DeleteActionPlanDialog />

      <CreateNewCategoryDialog />
      <DeleteCategoryDialog />

      <CreateNewPointerDialog />
      <DeletePointerDialog />
    </>
  );
}

export default ActionPlans;
