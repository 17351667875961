import React, { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../components/common/Alert";
import { disableCustomer, selectselectedCustomer } from "../redux/slices/adminSlice";

function DeleteCustomerDialog() {
  const selectedCustomer = useSelector(selectselectedCustomer);
  const dispatch = useDispatch();
  const [error, seterror] = useState(null);
  const closeDialogRef = useRef();
  const [btnDisabled, setbtnDisabled] = useState(true);

  const closeDialog = () => {
    closeDialogRef.current.click();
  };

  return (
    <>
      <div className="modal fade" id="deleteCustomerDialog" tabIndex={-1} aria-labelledby="deleteCustomerDialogLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h2 style={{ marginBottom: "16px" }}>Remove Account</h2>

              <div className="form-field">Removing User will erase both the user & data. If you want to retain the data Disable the user.</div>

              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue
                  id="flexCheckDefault"
                  onChange={(e) => {
                    setbtnDisabled(!e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I am sure I want to remove the user & erase data.
                </label>
              </div>

              {error && <Alert value={error} variant={"danger"} />}

              <div className="hstack gap-2 d-flex justify-content-end">
                <button type="button" class="btn btn-lg btn-outline-primary" data-bs-dismiss="modal" ref={closeDialogRef}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={btnDisabled}
                  class="btn btn-lg btn-danger"
                  onClick={() => {
                    dispatch(disableCustomer({ userId: selectedCustomer?.id, seterror, closeDialog }));
                  }}
                >
                  Disable Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteCustomerDialog;
